import React from 'react'

const Footer = () => {
  return (
<>
<footer className="footer bg-primary mb-0">
  <div className="container">
    <div className="row justify-content-md-between justify-content-evenly py--1">
      <div className="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
        <p className="fs--1 my-2 fw-bold text-200">All rights Reserved &copy; Bioinformatics group of the BIO@SNS, Scuola Normale Superiore, Pisa (Italy) - 2023 </p>
      </div>
    </div>
  </div>
</footer>
</>
  )
}

export default Footer