import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HeatMap from '../../components/HeatMap/HeatMap';
import BarPlot from '../../components/BarPlot/BarPlot';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
import { useNavigate, useLocation } from "react-router-dom";


const ExplorePage = () => {

const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
    // Check if location is undefined or null
    if (!location.state) {
      // Redirect to the home page
      navigate('/');
    }
}, [location, navigate]);

const state = location.state ||  [];
const pathway_level_metabolism = state.pathway_level_metabolism ||  [] ;
const subtypes_level_metabolism = state.subtypes_level_metabolism  ||  [];
const gene_heatmaps_metabolism = state.gene_heatmaps_metabolism  ||  [];
const height_metabolism = state.height_metabolism  ||  [];
const pathway_level_signaling = state.pathway_level_signaling ||  [] ;
const subtypes_level_signaling = state.subtypes_level_signaling  ||  [];
const gene_heatmaps_signaling = state.gene_heatmaps_signaling  ||  [];
const height_signaling = state.height_signaling  ||  [];
const cross_refs = state.cross_refs || [];
const coupling_level = state.coupling_level || [];
const bubble_plot = state.bubble_plot || [];
const bubble_plot_subtype = state.bubble_plot_subtype || [];
const survival = state.survival || [];
const survival_subtype = state. survival_subtype || [];
const survival_tissue_ligand_enzyme = state.survival_tissue_ligand_enzyme || [];
const survival_tissue_gpcr_ligand_enzyme = state.survival_tissue_gpcr_ligand_enzyme  || [];
const survival_tissue_ligand_enzyme_subtype = state.survival_tissue_ligand_enzyme_subtype || [];
const survival_tissue_gpcr_ligand_enzyme_subtype = state.survival_tissue_gpcr_ligand_enzyme_subtype || [];
const g_proteins = state.g_proteins || [];
const class_level = state.class_level || [];
const family_level = state.family_level || [];
const tissue_data = state.tissue_data || [];
const tissue = state.tissue || "";
const gene_subtypes = state.gene || "";
const bar_subtypes_data = state.sub_bar || [];
const bar_tissue_data = state.tis_bar || [];

const [gene_heatmaps, setGeneHeatmaps] = useState(gene_heatmaps_metabolism || gene_heatmaps_signaling || []);
const [pathway_level, setPathwayLevel] = useState(pathway_level_metabolism || pathway_level_signaling || []);
const [subtypes_level, setSubtypesLevel] = useState(subtypes_level_metabolism || subtypes_level_signaling || []);
const [heatmaps_data, setHeatmapData] = useState(gene_heatmaps_metabolism || gene_heatmaps_signaling || []);
const [height, setHeight] = useState(height_metabolism || height_signaling || []);
const [title, setTitle] = useState("Pathway-level");
const [selectedProcess, setSelectedProcess] = useState();
const [selectedLevel, setSelectedLevel] = useState("Pathway-level");
const [selectedType, setSelectedType] = useState("Tissue");
const [selectedTab, setSelectedTab] = useState(cross_refs[0]);
const [heatmaps_data_coupling_level, setHeatmapDataCoupling] = useState(coupling_level[0] || []);
const [heatmaps_data_class_level, setHeatmapDataClass] = useState(class_level[0] || []);
const [heatmaps_data_family_level, setHeatmapDataFamily] = useState(family_level[0] || []);
const [selectedGProtein, setSelectedGProtein] = useState();
const [gene_subtypes_title, setGeneSubTitle] = useState();
const [clickedValue, setClickedValue] = useState(null);
const [tissue_data_sub, setTissueDataSub] = useState({'geneUp': []});
const [tissue_data_tis, setTissueDataTis] = useState({'geneUp': []});
const [hoverMessage, setHoverMessage] = useState(true);


useEffect(() => {

    if (gene_heatmaps_signaling.length > 0 && gene_heatmaps_metabolism.length === 0){
        setSelectedProcess("Signalling");
        setPathwayLevel(pathway_level_signaling);
        setSubtypesLevel(subtypes_level_signaling);
        setGeneHeatmaps(gene_heatmaps_signaling);
        setHeatmapData(gene_heatmaps_signaling[0]);
        setHeight(height_signaling);

    }else if (gene_heatmaps_metabolism.length > 0 && gene_heatmaps_signaling.length === 0){
        setSelectedProcess("Metabolism");
        setPathwayLevel(pathway_level_metabolism);
        setSubtypesLevel(subtypes_level_metabolism);
        setGeneHeatmaps(gene_heatmaps_metabolism);
        setHeatmapData(gene_heatmaps_metabolism[0]);
        setHeight(height_metabolism);

    }else if (gene_heatmaps_metabolism.length > 0 && gene_heatmaps_signaling.length > 0){
        setSelectedProcess("Metabolism");
        setPathwayLevel(pathway_level_metabolism);
        setSubtypesLevel(subtypes_level_metabolism);
        setGeneHeatmaps(gene_heatmaps_metabolism);
        setHeatmapData(gene_heatmaps_metabolism[0]);
        setHeight(height_metabolism);
    }

}, []);


// Check if an element is in array
function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

const handleTab = (e) => {
  setSelectedTab(e.target.value);
};

const handleGProteinChange = (e) => {
  setSelectedGProtein(e.target.value);
  setHeatmapDataCoupling(coupling_level[e.target.value]);
};

const handlePathwayInput = (e) => {
    if (selectedProcess === "Metabolism"){
      setHeatmapData(gene_heatmaps_metabolism[e.target.value]);
    } else if (selectedProcess === "Signalling"){
      setHeatmapData(gene_heatmaps_signaling[e.target.value]);
    }
};

const handleLevelChange = (e) => {
   setSelectedLevel(e.target.value);

   if (selectedProcess === "Metabolism"){
      setGeneHeatmaps(gene_heatmaps_metabolism);
      setHeatmapData(gene_heatmaps_metabolism[0]);
    } else if (selectedProcess === "Signalling"){
      setGeneHeatmaps(gene_heatmaps_signaling);
      setHeatmapData(gene_heatmaps_signaling[0]);
    }

};

const handleProcess = (e) => {
   setSelectedProcess(e.target.value);

   if (e.target.value === "Metabolism"){
      setPathwayLevel(pathway_level_metabolism);
      setSubtypesLevel(subtypes_level_metabolism);
      setHeight(height_metabolism);
      setGeneHeatmaps(gene_heatmaps_metabolism);
      setHeatmapData(gene_heatmaps_metabolism[0]);
    } else if (e.target.value === "Signalling"){
      setPathwayLevel(pathway_level_signaling);
      setSubtypesLevel(subtypes_level_signaling);
      setGeneHeatmaps(gene_heatmaps_signaling);
      setHeatmapData(gene_heatmaps_signaling[0]);
      setHeight(height_signaling);
    }
};

const handleTypeChange = (e) => {
   setSelectedType(e.target.value);
};

// Function to update the state with the clicked value
const handleClickedValue = (value) => {
    setClickedValue(value);
    setGeneSubTitle(value);

    if (bar_subtypes_data.length > 0){
        const parsedData = JSON.parse(bar_subtypes_data)
        setTissueDataSub(parsedData[value]);
    }

    console.log(bar_tissue_data)
    if (bar_tissue_data.length > 0){
        const parsedDataTis = JSON.parse(bar_tissue_data)
        setTissueDataTis(parsedDataTis[value]);
    }

};

const onClickHandler = () => {
 Swal.fire({
  html: `
     <div className="container my-2">
     <h2 className="display-6 fw-bold mb-2">Info</h2>
     <p className="text-justify fs-1">GPCR CANCER AXES is a new pipeline and webserver to explore the dysregulation of
                GPCR ligand signaling systems in cancer genomics datasets. We derived a network of interacting ligands and
                biosynthetic enzymes from public databases, that we combined with cognate GPCRs and downstream effectors
                to quantify GPCR signaling pathways.</p>
     <p className="text-justify fs-1">For more information, please refer to the <a className="italic" href="/about/" target="_blank">about</a> page.</p>
     </div>
  `,
  showCloseButton: true,
});;
};


const onClickHandlerHelp = () => {
 Swal.fire({
  html: `
     <div className="container my-2">
     <h2 className="display-6 fw-bold mb-2">Help</h2>
     <p className="text-justify fs-1">We provides various filters that enable the visualization of the heatmap according to your specific requirements.
     Please note that the available filters may be updated based on the available results.</p>
     <p className="text-justify fs-1">For more information, please refer to the <a className="italic" href="/help/" target="_blank">help</a> page.</p>
     </div>
  `,
  showCloseButton: true,
});;
};


return(
  <>
    <Helmet>
        <title>GCA | Explore</title>
      </Helmet>
      <Header/>
      <section className="my-1">
       <div className="container px-3">
        <div className="row mb-2">
            <div className="col-12 d-flex align-items-center">
              <h2 className="display-6 fw-bold">Explore
                 <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandler}><ion-icon name="information-outline"></ion-icon></button>
                 </span>
              </h2>
            </div>
          </div>
         {(cross_refs[0] === "Gene level" || cross_refs[0] === "Pathway level" && cross_refs.length > 1)&&
        <div className="btn-toolbar" role="toolbar">
         <div className="btn-group me-2" role="group" aria-label="First group">
              {cross_refs.map((ref, index) => (
                <>
                 <button type="button" key={index} className={`tabs__btn fw-medium fs--1 ${selectedTab === ref ? 'tabs__btn__active' : ''}`} value={ref} onClick={handleTab}>{ref}</button>
                </>
              ))}
            </div>
            </div>
         }
          {(selectedTab === "Gene level" || selectedTab === "Pathway level") &&
                 <div className="container mt-4 mb-2">
                    <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4"> {cross_refs[0]}
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                        <div className="rounded-2 ms-3 shadow col-12 mb-4">
                            <h6 className="fw-bold">Filter by</h6>
                            <div className="row mb-3">
                             {(gene_heatmaps_metabolism.length > 0 && gene_heatmaps_signaling.length > 0) && (
                                <div className="col-sm-4 col-md-3 col-lg-2">
                                  <div className="form-floating rounded">
                                    <select className="form-select form-select-lg" value={selectedProcess} onChange={handleProcess} aria-label="process">
                                      <option value="Metabolism">Metabolism</option>
                                      <option value="Signalling">Signalling</option>
                                    </select>
                                    <label>Select the process</label>
                                  </div>
                                </div>
                              )}
                                <div className="col-sm-4 col-md-3 col-lg-2">
                                    <div className="form-floating rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedLevel}
                                            onChange={handleLevelChange}
                                            aria-label="pathway">
                                            <option value="Pathway-level">Pathway-level</option>
                                            <option value="Gene-level">Gene-level</option>
                                        </select>
                                        <label>Select the level</label>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3 col-lg-2 mb-1">
                                    <div className="form-floating rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            aria-label="type">
                                            <option value="Tissue">Tissue</option>
                                            <option value="Subtypes">Subtypes</option>
                                        </select>
                                        <label>Select the type</label>
                                    </div>
                                </div>
                                 {(selectedLevel === "Gene-level" && gene_heatmaps.length > 1) &&
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-floating rounded">
                                        <select type="text" className="form-select form-select-lg" defaultValue={gene_heatmaps[0][2]}  aria-label="pathway" onChange={handlePathwayInput}>
                                          {gene_heatmaps.map((heatmap, index) => (
                                             <option className="title-case" value={index} key={heatmap[2]}>{heatmap[2]}</option>
                                          ))}
                                        </select>
                                        <label>Select the pathway</label>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                           {(gene_heatmaps.length > 0 && selectedLevel === "Gene-level") &&
                             <div className="row g-2 px-3">
                               <figcaption className="figure-caption text-center title-case fs-1 mb-3">{heatmaps_data[2]}</figcaption>
                               {(selectedLevel === "Gene-level" && selectedType === "Tissue") &&
                                <div className="col-6 col-sm-6 col-md-6">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data[5]}}>
                                    <HeatMap jsonData={heatmaps_data[0]} heatmap_id={heatmaps_data[3]}/>
                                  </div>
                                </div>
                                }
                                 {(selectedLevel === "Gene-level" && selectedType === "Subtypes") &&
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data[5]}}>
                                    <HeatMap  className="rounded-3 shadow img-fluid" jsonData={heatmaps_data[1]} heatmap_id={heatmaps_data[4]}/>
                                  </div>
                                </div>
                                }
                             </div>
                           }
                            {(selectedLevel === "Pathway-level") &&
                             <div className="row g-2 px-3">
                               <figcaption className="figure-caption text-center fs-1 mb-3">{title} ({selectedProcess})</figcaption>
                               {(selectedLevel === "Pathway-level" && selectedType === "Tissue") &&
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="rounded-3 shadow" style={{ height: height}}>
                                        <HeatMap jsonData={pathway_level} heatmap_id={"heatmap_pathway_level"} />
                                    </div>
                                </div>
                                }
                                 {(selectedLevel === "Pathway-level" && selectedType === "Subtypes") &&
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="rounded-3 shadow" style={{ height: height}}>
                                        <HeatMap jsonData={subtypes_level}  heatmap_id={"heatmap_subtypes_level"} />
                                    </div>
                                </div>
                                }
                             </div>
                           }
                    </div>
                </div>
            }
            {selectedTab === "G-Protein coupling" &&
              <div className="container mt-4 mb-2">
                <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4">G-Protein coupling
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                       <div className="rounded-2 ms-3 shadow col-3 mb-4">
                            <h6 className="fw-bold">Filter by</h6>
                            <div className="row mb-3">
                                <div className="col-12 col-sm-12">
                                    <div className="form-floating  rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            aria-label="type">
                                            <option value="Tissue">Tissue</option>
                                            <option value="Subtypes">Subtypes</option>
                                        </select>
                                        <label>Select the type</label>
                                    </div>
                                </div>
                            </div>
                       </div>
                 </div>
                 {(selectedTab === "G-Protein coupling" && heatmaps_data_coupling_level && heatmaps_data_coupling_level.length > 0) &&
                        <div className="row g-2 px-3">
                            <figcaption className="figure-caption text-center fs-1 mb-3">{heatmaps_data_coupling_level[2]}</figcaption>
                             {(selectedType === "Tissue") &&
                                <div className="col-6">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_coupling_level[5]}}>
                                    <HeatMap jsonData={heatmaps_data_coupling_level[0]} heatmap_id={heatmaps_data_coupling_level[3]}/>
                                  </div>
                                </div>
                                }
                                 {(selectedType === "Subtypes") &&
                                <div className="col-12">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_coupling_level[5]}}>
                                    <HeatMap  className="rounded-3 shadow img-fluid" jsonData={heatmaps_data_coupling_level[1]} heatmap_id={heatmaps_data_coupling_level[4]}/>
                                  </div>
                                </div>
                                }
                        </div>
                    }
               </div>
            }
            {selectedTab === "G-Protein Coupling" &&
              <div className="container mt-4 mb-2">
                <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4">G-Protein coupling
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                       <div className="rounded-2 ms-3 shadow col-sm-6 col-md-5 col-lg-4  mb-4">
                            <h6 className="fw-bold">Filter by</h6>
                            <div className="row mb-3">
                            {(selectedTab === "G-Protein Coupling" && g_proteins.length > 1) &&
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-floating  rounded">
                                        <select type="text" className="form-select form-select-lg" defaultValue={g_proteins[0]}
                                            value={selectedGProtein}
                                            onChange={handleGProteinChange}
                                            aria-label="G-protein">
                                             {g_proteins.map((g_protein, index) => (
                                             <option value={index} key={g_protein}>{g_protein}</option>
                                          ))}
                                        </select>
                                        <label>Select the G-Protein</label>
                                    </div>
                                </div>
                                }
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-floating  rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            aria-label="type">
                                            <option value="Tissue">Tissue</option>
                                            <option value="Subtypes">Subtypes</option>
                                        </select>
                                        <label>Select the type</label>
                                    </div>
                                </div>
                            </div>
                       </div>
                 </div>
                 {(selectedTab === "G-Protein Coupling" && heatmaps_data_coupling_level && heatmaps_data_coupling_level.length > 0) &&
                        <div className="row g-2 px-3">
                            <figcaption className="figure-caption text-center fs-1 mb-3">{heatmaps_data_coupling_level[2]}</figcaption>
                             {(selectedType === "Tissue") &&
                                <div className="col-6 col-md-8 col-sm-7">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_coupling_level[5]}}>
                                    <HeatMap jsonData={heatmaps_data_coupling_level[0]} heatmap_id={heatmaps_data_coupling_level[3]}/>
                                  </div>
                                </div>
                                }
                                 {(selectedType === "Subtypes") &&
                                <div className="col-12 col-md-12 col-sm-12">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_coupling_level[5]}}>
                                    <HeatMap  className="rounded-3 shadow img-fluid" jsonData={heatmaps_data_coupling_level[1]} heatmap_id={heatmaps_data_coupling_level[4]}/>
                                  </div>
                                </div>
                                }
                        </div>
                    }
               </div>
            }
             {selectedTab === "Class level" &&
              <div className="container mt-4 mb-2">
                <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4">Class level
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                       <div className="rounded-2 ms-3 shadow col-sm-3 col-md-3 col-lg-3 mb-4">
                            <h6 className="fw-bold">Filter by</h6>
                            <div className="row mb-3">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="form-floating rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            aria-label="type">
                                            <option value="Tissue">Tissue</option>
                                            <option value="Subtypes">Subtypes</option>
                                        </select>
                                        <label>Select the type</label>
                                    </div>
                                </div>
                            </div>
                       </div>
                 </div>
                 {(selectedTab === "Class level" && heatmaps_data_class_level.length > 0) &&
                        <div className="row g-2 px-3">
                            <figcaption className="figure-caption text-center fs-1 mb-3">{heatmaps_data_class_level[2]}</figcaption>
                             {(selectedType === "Tissue") &&
                                <div className="col-6 col-sm-7 col-md-8">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_class_level[5]}}>
                                    <HeatMap jsonData={heatmaps_data_class_level[0]} heatmap_id={heatmaps_data_class_level[3]}/>
                                  </div>
                                </div>
                                }
                                 {(selectedType === "Subtypes") &&
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_class_level[5]}}>
                                    <HeatMap className="rounded-3 shadow img-fluid" jsonData={heatmaps_data_class_level[1]} heatmap_id={heatmaps_data_class_level[4]}/>
                                  </div>
                                </div>
                                }
                        </div>
                    }
               </div>
            }
              {selectedTab === "Family level" &&
              <div className="container mt-4 mb-2">
                <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4">Family level
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                       <div className="rounded-2 ms-3 shadow col-sm-3 col-md-3 col-lg-3 mb-4">
                            <h6 className="fw-bold">Filter by</h6>
                            <div className="row mb-3">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="form-floating rounded">
                                        <select type="text" className="form-select form-select-lg"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            aria-label="type">
                                            <option value="Tissue">Tissue</option>
                                            <option value="Subtypes">Subtypes</option>
                                        </select>
                                        <label>Select the type</label>
                                    </div>
                                </div>
                            </div>
                       </div>
                 </div>
                 {(selectedTab === "Family level" && heatmaps_data_family_level.length > 0) &&
                        <div className="row g-2 px-3">
                            <figcaption className="figure-caption text-center fs-1 mb-3">{heatmaps_data_family_level[2]}</figcaption>
                             {(selectedType === "Tissue") &&
                                <div className="col-6 col-sm-7 col-md-8">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_family_level[5]}}>
                                    <HeatMap jsonData={heatmaps_data_family_level[0]} heatmap_id={heatmaps_data_family_level[3]}/>
                                  </div>
                                </div>
                                }
                                 {(selectedType === "Subtypes") &&
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="rounded-3 shadow img-fluid" style={{ height: heatmaps_data_family_level[5]}}>
                                    <HeatMap className="rounded-3 shadow img-fluid" jsonData={heatmaps_data_family_level[1]} heatmap_id={heatmaps_data_family_level[4]}/>
                                  </div>
                                </div>
                                }
                        </div>
                    }
               </div>
            }
          {selectedTab === "Tissue-level" &&
              <div className="container mt-4 mb-2">
                <div className="row align-items-start">
                       <h4 className="fw-bold underline mb-4">Tissue level
                       <span className="badge">
                        <button type="button" className="btn btn-info"  onClick={onClickHandlerHelp}><ion-icon name="help-outline"></ion-icon></button>
                     </span>
                       </h4>
                 </div>
                 {(selectedTab === "Tissue-level") &&
                        <div className="row g-2 px-3">
                                <div className="col-8 mb-4">
                                  <figcaption className="figure-caption text-center fs-1 mb-3">{tissue}</figcaption>
                                  <div className="rounded-3 shadow img-fluid">
                                    <BarPlot className="rounded-3 shadow img-fluid" jsonData={tissue_data} handleClickEnabled={true} hoverMessage={hoverMessage} handleClickValue={handleClickedValue}/>
                                  </div>
                                </div>
                                <div className="col-4"></div>
                                 {tissue_data_tis.geneUp.length > 0 &&
                                <div className="col-6">
                                  <figcaption className="figure-caption text-center fs-1 mb-3"> {gene_subtypes_title} - Tissue</figcaption>
                                  <div className="rounded-3 shadow img-fluid">
                                    <BarPlot className="rounded-3 shadow img-fluid" jsonData={tissue_data_tis} handleClickEnabled={false} hoverMessage={false} handleClickValue={null}/>
                                  </div>
                                </div>
                                }
                                 {tissue_data_sub.geneUp.length > 0 &&
                                <div className="col-4">
                                  <figcaption className="figure-caption text-center fs-1 mb-3"> {gene_subtypes_title} - Subtypes</figcaption>
                                  <div className="rounded-3 shadow img-fluid">
                                    <BarPlot className="rounded-3 shadow img-fluid" jsonData={tissue_data_sub} handleClickEnabled={false} hoverMessage={false} handleClickValue={null}/>
                                  </div>
                                </div>
                                }
                        </div>
                    }
               </div>
            }
          {selectedTab === "Survival Analysis" && (
            <div className="container mt-4 mb-2">
              <div className="row align-items-start mb-4">
                <h4 className="fw-bold underline mb-2">Survival Analysis</h4>
              </div>
              <div className="row g-2 px-3 mb-4">
                    <img tabindex="1" src={bubble_plot} className="rounded-3 vw-sm-30 back-white shrink shadow img-fluid mb-3" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
               </div>
              <div className="row g-2 px-3 mb-4">
                    <img tabindex="1" src={bubble_plot_subtype} className="rounded-3 vw-sm-30 back-white shrink shadow img-fluid mb-3" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
               </div>
              {survival &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">G-protein coupled receptor</h5>
                 {survival.map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img tabindex="1" src={plot[0]} className="rounded-3 vw-sm-30 shrink shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
              {survival_tissue_ligand_enzyme.length > 0  &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">Ligand/Enzyme</h5>
                 {survival_tissue_ligand_enzyme .map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img src={plot[0]} className="rounded-3 vw-sm-30 shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
               {survival_tissue_gpcr_ligand_enzyme.length > 0   &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">GPCR + Ligand/Enzyme</h5>
                 {survival_tissue_gpcr_ligand_enzyme.map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img src={plot[0]} className="rounded-3 vw-sm-30 shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
              {survival_subtype.length > 0 &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">G-protein coupled receptor - Subtypes</h5>
                 {survival_subtype.map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img src={plot[0]} className="rounded-3 vw-sm-30 shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
               {survival_tissue_ligand_enzyme_subtype.length > 0  &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">Ligand/Enzyme - Subtypes</h5>
                 {survival_tissue_ligand_enzyme_subtype.map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img src={plot[0]} className="rounded-3 vw-sm-30 shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
              {survival_tissue_gpcr_ligand_enzyme_subtype.length > 0 &&
                <div className="row g-2 px-3 mb-4">
                  <h5 class="fw-bold underline mb-2">GPCR + Ligand/Enzyme -Subtypes</h5>
                 {survival_tissue_gpcr_ligand_enzyme_subtype.map((plot, index) => (
                  <>
                       <figcaption className="figure-caption text-center fs-1 mb-2">{plot[1]}</figcaption>
                       <img src={plot[0]} className="rounded-3 vw-sm-30 shadow img-fluid mb-2" loading="lazy" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                   </>
                ))}
               </div>
              }
            </div>
          )}
     </div>
    </section>
  <Footer/>
</>

)

}

export default ExplorePage