import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';


import "./index.css"

// Import Pages
import HomePage from './pages/HomePage/HomePage'
import Under from './pages/Under/Under'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import ExplorePage from './pages/ExplorePage/ExplorePage'
import HelpPage from './pages/HelpPage/HelpPage'
import AboutPage from './pages/AboutPage/AboutPage'
import FaqPage from './pages/FaqPage/FaqPage'

const router = createBrowserRouter([ {
      path: "/",
      element: <HomePage/>,
      errorElement: <ErrorPage error={"Sorry for the inconvenience, we're working on it!"}/>
    },
    {
      path: "/under/",
      element: <Under/>,
    },
    {
      path: "/explore/",
      element: <ExplorePage/>,
    },
    {
      path: "/help/",
      element: <HelpPage/>,
    },{
      path: "/about/",
      element: <AboutPage/>,
    },
    ,{
      path: "/faq/",
      element: <FaqPage/>,
    },
     {
      path: "*",
      element: <ErrorPage error={"Page not found. This page doesn't exist!"}/>
    },
  ],
 );

createRoot(document.getElementById("root")).render(
<RouterProvider router={router}/>);
