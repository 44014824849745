import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { Divider } from 'primereact/divider';


const FaqPage = () => {

   return (
    <>
    <Helmet>
        <title>GCA | FAQs </title>
      </Helmet>
      <Header/>
      <section className="px-3">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-sm-start text-center">
                    <h1 className="display-5 fw-bold mb-4">FAQs</h1>

                      <p className="fs-1 text-justify"><b>Which type of information is provided by this webserver?</b><br/>
                      The web server provides a range of tools to explore the dysregulation of G protein-coupled receptor (GPCR)
                      ligand signalling systems in cancer genomics datasets from various levels of analysis. Users can visualise a
                       heatmap of GSEA pathway enrichment analysis for differentially expressed (DE) genes in cancer (TCGA) over normal
                       (GTEx) samples, considering Reactome pathways related to signalling and metabolism. At the gene level,
                       DE heatmaps show expression patterns for a pathway. In contrast, differential receptor expression heatmaps
                       explore G-protein coupling specificity. Moreover, the server visually presents associations between GPCR-peptide
                       ligands or GPCR enzymes and survival, enabling stratification based on receptor-ligand or receptor-enzyme expression
                        and providing insights into their correlation with patient outcomes.
                      Lastly, family, class, and tissue-level analyses further dissect gene expression patterns across different contexts.
                        </p>
                <Divider  className="mb-2"/>
                <p className="fs-1 text-justify"><b>How can I access the information?</b><br />
                    You can search for information by entering a gene symbol, pathway name, family name, class name, or tissue name.</p>

                </div>
            </div>
        </div>
</section>

    <Footer/>
   	</>
   )
}

export default FaqPage