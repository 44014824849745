import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';


const HelpPage = () => {

   return (
    <>
    <Helmet>
        <title>GCA | Help </title>
      </Helmet>
      <Header/>
      <section className="px-3">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-sm-start text-center">
                    <h1 className="display-5 fw-bold mb-4">Help Page</h1>
                    <h2 className="display-6 fw-bold mb-3">Web server usage</h2>
                     <p className="fs-1 mb-5 text-justify mb-5">To obtain a comprehensive overview of
                     G protein-coupled receptors (GPCRs) and their pathways,
                    it's advisable to conduct a Gene query. These approaches reveals
                    all relevant pathways with significant occurrences. Alternatively, outcomes can
                    be generated based on GPCR family, class or G-protein coupling specificity, focused on the family, class and G-coupling levels.</p>
                    <img tabindex="1" src="/assets/images/homepage.png" className="center-help shrink img-fluid" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                     <p className="fs-1 text-justify mb-5">On our web server's main page, users can input their search queries and receive a
                     list of suggested values related to the query type they select.</p>
                    <h2 className="display-6 fw-bold mb-3" id="gpcr-level">Filters</h2>
                    <p className="fs-1 mb-5 text-justify mb-5">While browsing our web server, users can obtain relevant results based on their search queries and filter them according to their interests.</p>
                     <img tabindex="1" src="/assets/images/GPCR-level-filters.png" className="center-help shrink img-fluid mb-5" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                    <h3 className="fw-bold mb-3">Pathway-level</h3>
                    <p className="fs-1 mb-5 text-justify mb-5">Heatmap of GSEA pathway enrichment analysis for
                     differentially expressed (DE) genes in cancer (TCGA) over normal (GTEx) samples,
                      considering Reactome pathways related to signaling and metabolism.
                      Red cells indicate pathways enriched in TCGA, blue cells indicate pathways enriched in GTEx,
                      and white cells indicate no enrichment. Cells marked with ‘*’ correspond to a statistically
                      significant enrichment (Padj {'<'} 0.01).</p>
                      <img src="/assets/images/pathway-level-signalling.png" className="center-help img-fluid mb-5"/>
                     <h3 className="fw-bold mb-3">Gene-level</h3>
                      <p className="fs-1 mb-5 text-justify mb-5">Heatmap of DE genes for
                        a pathway. Red cells indicate upregulated genes in TCGA, blue cells indicate downregulated genes in TCGA, and
                        cells marked with ‘*’ correspond to a statistically significant differential expression (Padj {'<'}
                        0.01).</p>
                        <img tabindex="1" src="/assets/images/gene-level.png" className="center-help img-fluid mb-5"/>
                        <h2 className="display-6 fw-bold mb-3">G-Protein coupling</h2>
                         <p className="fs-1 mb-5 text-justify mb-5">Heatmap of DE receptors based on their G-protein coupling specificity. Red cells indicate upregulated genes in TCGA, blue cells indicate downregulated genes in TCGA, and
                        cells marked with ‘*’ correspond to a statistically significant differential expression (Padj {'<'}
                        0.01).</p>
                        <img tabindex="1" src="/assets/images/GNAS.png" className="center-help img-fluid mb-5"/>
                        <h2 className="display-6 fw-bold mb-3">Survival Analysis</h2>
                        <p className="fs-1 mb-5 text-justify mb-5">Kaplan-Meier curve for survival analysis of patients stratified based on the individual as well as combined receptor/ligand expression</p>
                        <img src="/assets/images/Brain_CHRM3.webp" className="center-help img-fluid mb-5"/>
                        <h3>Association of GPCR-peptide ligands axes to survival</h3>
                        <p className="fs-1 mb-5 text-justify mb-5">The bubble plot
                        shows the correlation between the combined-expression levels of GPCR-ligand pairs and
                        patient survival across various cancer types. The pairs with a log rank p-value {'<'} 0.05 and
                        also lower than log rank p-values for individual GPCR/ligand are displayed. Bubble color is
                        proportional to HR: i.e., HR {'>'} 1 : High expression is correlated with high survival (red);
                        HR {'<'} 1: High expression is correlated with poor survival (blue). Bubble diameters are proportional to
                        the -log10(log-rank p-value). Green highlighted bubbles represent the most significant
                        instances (sample sizes {'>'} 5, FDR {'<'} 0.1). </p>
                        <img src="/assets/images/Bubbleplot_receptor_ligand_sample_filter.webp" className="center-help img-fluid mb-5"/>
                        <h3>Association of GPCR-enzymes axes to survival</h3>
                        <p className="fs-1 mb-5 text-justify mb-5">The bubble plot shows the
                        correlation between the combined-expression levels of GPCR-enzyme pairs and patient
                        survival across various cancer types. The pairs with a log rank p-value  {'<'} 0.05 and also lower
                        than log rank p-values for individual GPCR/enzyme are displayed. Further, the pairs with
                        rate-limiting enzymes having less than 100 PubMed references are not shown. Bubble color
                        is proportional to HR: i.e., HR  {'>'} 1 : High expression is correlated with high survival (red);
                        HR  {'<'} 1: High expression is correlated with poor survival (blue). Bubble diameters are
                        proportional to the -log(log-rank p-value). Green highlighted bubbles represent the most
                        significant instances (sample sizes>5, FDR  {'<'} 0.1).</p>
                        <img src="/assets/images/Bubbleplot_receptor_enzyme_sample_filter.webp" className="center-help img-fluid mb-5"/>
                         <h2 className="display-6 fw-bold mb-3">Family level</h2>
                        <p className="fs-1 mb-5 text-justify mb-5">Heatmap of DE genes based on their GPCR family. Red cells indicate upregulated genes in TCGA, blue cells indicate downregulated genes in TCGA, and
                        cells marked with ‘*’ correspond to a statistically significant differential expression (Padj {'<'}
                        0.01).</p>
                        <img src="/assets/images/family.png" className="center-help img-fluid mb-5"/>
                        <h2 className="display-6 fw-bold mb-3">Class level</h2>
                        <p className="fs-1 mb-5 text-justify mb-5">Heatmap of DE genes based on their GPCR class. Red cells indicate upregulated genes in TCGA, blue cells indicate downregulated genes in TCGA, and
                        cells marked with ‘*’ correspond to a statistically significant differential expression (Padj {'<'}
                        0.01).</p>
                        <img src="/assets/images/class.png" className="center-help img-fluid mb-5"/>
                          <h2 className="display-6 fw-bold mb-3">Tissue level</h2>
                        <p className="fs-1 mb-5 text-justify mb-5">The bar chart displays the top 20 G protein-coupled receptors (GPCRs) that were significantly up-
                        or down-regulated in a given tissue.</p>
                        <img src="/assets/images/tissue_lung.png" className="center-help img-fluid mb-5"/>
                        <p className="fs-1 mb-5 text-justify mb-5">To access detailed tissue and subtype data, kindly click on the corresponding gene bar. If available, the required information will be displayed for your reference.</p>
                         <img src="/assets/images/QQ.png" className="center-help img-fluid mb-5"/>

                         <img src="/assets/images/QRFPR.png" className="center-help img-fluid mb-5"/>
                </div>
            </div>
        </div>
</section>

    <Footer/>
   	</>
   )
}

export default HelpPage