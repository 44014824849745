import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';


const AboutPage = () => {

   return (
    <>
    <Helmet>
        <title>GCA | About </title>
      </Helmet>
      <Header/>
      <section className="px-3">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-sm-start text-center">
                    <h1 className="display-5 fw-bold mb-4">About Page</h1>
                        <p className="fs-1 mb-5 text-justify mb-5">GPCR CANCER AXES is a new pipeline and webserver to explore
                         the dysregulation of GPCR ligand signaling systems in cancer genomics datasets.</p>
                    <img tabindex="1" src="/assets/images/fx1_lrg.jpg" className="center-help shrink img-fluid mb-2" data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                          <p className="fs-1 mb-3 text-justify">We explored the dysregulation of G-protein-coupled receptor (GPCR) ligand systems in cancer transcriptomics
                            datasets to uncover new therapeutics opportunities in oncology. We derived an interaction network of receptors
                            with ligands and their biosynthetic enzymes. Multiple GPCRs are differentially regulated together
                            with their upstream partners across cancer subtypes and are associated to specific transcriptional programs
                            and to patient survival patterns. The expression of both receptor-ligand (or enzymes) partners improved patient
                            stratification, suggesting a synergistic role for the activation of GPCR networks in modulating cancer
                            phenotypes. Remarkably, we identified many such axes across several cancer molecular subtypes, including
                            many involving receptor-biosynthetic enzymes for neurotransmitters. We found that GPCRs from these
                            actionable axes, including, e.g., muscarinic, adenosine, 5-hydroxytryptamine, and chemokine receptors,
                            are the targets of multiple drugs displaying anti-growth effects in large-scale, cancer cell drug screens, which
                            we further validated.</p>
                            <h2 className="display-6 fw-bold mb-3">Libraries</h2>
                             <ul class="mb-1 ml-10">
                                <li class="fs-1 text-justify">React.js (v18.2.0)</li>
                                <li class="fs-1 text-justify">Plotly.js  (2.27.0)</li>
                                <li class="fs-1 text-justify">Flask (v2.2.2)</li>
                                <li class="fs-1 text-justify mb-4">Mysql-connector-python (v8.1.0)</li>
                            </ul>
                            <h2 className="display-6 fw-bold mb-3">Cite</h2>
                            <p class="fs-1 mb-2 text-justify">
                            Arora, Chakit; Matic, Marin; Bisceglia, Luisa; Di Chiaro, Pierluigi; De Oliveira Rosa, Natalia; Carli, Francesco; Clubb, Lauren; Amir Nemati Fard, Lorenzo; Kargas, Giorgos; R. Diaferia, Giuseppe;
                             Vukotic, Ranka; Licata Luana; Wu, Guanming; Natoli, Gioacchino; Gutkind, J. Silvio; Raimondi, Francesco,
                            The landscape of cancer-rewired GPCR signaling axes, Cell Genomics, Volume 4, Issue 5, 100557,
                            <a href="https://doi.org/10.1016/j.xgen.2024.100557" target="_blank"> https://doi.org/10.1016/j.xgen.2024.100557</a></p>

                            <h2 class="display-6 fw-bold mb-3">Contact</h2>
                            <p class="fs-1 text-justify">Francesco Raimondi - francesco.raimondi@sns.it</p>
                </div>
            </div>
        </div>
</section>

    <Footer/>
   	</>
   )
}

export default AboutPage