import React, { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import CookieConsent from "react-cookie-consent";
import axios from 'axios';
import Swal from 'sweetalert2'
import { AutoComplete } from 'primereact/autocomplete';

const HomePage = () => {

// Create variables
const navigate = useNavigate();
const[searchType, setSearchType ] = useState('GPCR Level');
const initialValues = { gene: ""};
const [formValues, setFormValues] = useState(initialValues);
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);
const [error, setError] = useState(null);
const [loading, setIsLoading] = useState(false);
const [items, setItems] = useState([]);

const search = async (e) => {
    let list = items;

    // Read data from json
    const data = require('../../autocomplete_input.json');

    switch (searchType) {
        case "GPCR Level":
            list = data.gpcrs;
            break;
        case "GPCR-Family Level":
            list = data.families;
            break;
        case "GPCR-Class Level":
            list = data.classes;
            break;
        case "G-Coupling Level":
            list = data.gcoupling;
            break;
        case "Pathway Level":
            list = data.pathways;
            break;
        case "Tissue Level":
            list = data.tissues;
            break;
        default:
            console.error("Invalid search type");
            return;
    }

    const minLength = formValues.gene.length >= 2 ? formValues.gene.length : 1;

    const searchInput = list.filter(entry => {
    // Check if at least two letters of event.target match entry
    const targetSubstring = formValues.gene.slice(0, minLength).toLowerCase();
    const entryIdSubstring = entry.id.slice(0, minLength).toLowerCase();
    return entryIdSubstring === targetSubstring;
   });

    const searchInputIds = searchInput.map(entry => entry.id);
    setItems(searchInputIds);
};



// Set values
const searchTypeHandler = (e) => {
setSearchType(e.target.value);
};

const onChangeHandler = (e) => {const { name, value } = e.target;
setFormValues({ ...formValues, [name]: value });  };

useEffect(() => {

if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, formValues, isSubmit]);


const validateForm = (values) => { const errors = {};
    if (!values.gene) {
      errors.gene = "Gene is required!";
    }else if (values.gene.length < 3){
        errors.gene = "Input must have at least 4 characters!";
    }
    return errors;};

const onSubmitHandler = (e) => {
  e.preventDefault();
  const error = validateForm(formValues);
  setFormErrors(error);

   if(!error.gene){
      setIsLoading(true);
      sendExploreData();
   }

   setIsSubmit(true);
};


// Function to send form data to the server
async function sendExploreData() {
    try {

    const response = await axios.post('https://api.gpcrcanceraxes.bioinfolab.sns.it/api/explore/', {'gene': formValues.gene, 'search_type': searchType});
    // POMC / Metabolism Pathways  CCR1 / Signalling Pathways  CYP1A2 / Metabolism Pathways PRKACA  HTR6 CHRM5
    if (response.data.error) {
            setIsLoading(false);
            Swal.fire({
                icon: "info",
                text: response.data.error + ". Please change your search criteria and try again."
            });
    }

    if (!response.data.error) {

           setIsLoading(false);

           // Navigate to the explore page with the received data
           navigate('/explore/', { state: response.data });
    }


    } catch (error) {

      setIsLoading(false);

     setError('An error occurred: ' + error.message);

      Swal.fire({
          icon: "error",
          text: error.message
      });
   }
}


   return (
   <>

      <Helmet>
          <title>GCA | Home</title>
      </Helmet>
      <Header/>
<section className="py-0" id="home">
     <div className="bg-holder d-sm-block img-fluid bg" id="back"
         style={{backgroundImage: 'url(/assets/images/network.png)', display: 'none'}}>
    </div>

   <div className="container">
        <div className="row align-items-center min-vh-100 min-vh-md-100 vh-sm-100 vh-100">
            <div className="col-sm-6 col-md-6 col-lg-6 text-sm-start text-center">
                <span className="text-uppercase">Helping you find answers</span>
                <h1 className="display-2 fw-semi-bold lh-sm fs-4 fs-lg-6 fs-xxl-8">GPCR CANCER AXES</h1>
                <p className="mb-4 fs-1 fs-lg-1 fs-xxl-2">
                    Explore the dysregulation of GPCR ligand signaling systems in cancer genomics datasets.
                </p>
                <p className="mb-2 fs-0 fs-lg-0 fs-xxl-1">
                    Select your type of query
                </p>
                <div className="btn-group btn-group-sm mb-3 d-flex " role="group" aria-label="Basic radio toggle button group" value={searchType} onChange={searchTypeHandler}>
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" value="GPCR Level" defaultChecked={searchType === 'GPCR Level'} onChange={searchTypeHandler} />
                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Gene Level</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" value="GPCR-Family Level"/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Family Level</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" value="GPCR-Class Level"/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio3">Class Level</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" value="G-Coupling Level"/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio4">G-Coupling Level</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" value="Pathway Level"/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio5">Pathway Level</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio6" autoComplete="off" value="Tissue Level"/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio6">Tissue Level</label>

                </div>
                <form id="search-box" method="post" className="search-box mb-3" onSubmit={onSubmitHandler}>
                    <div className="input-group w-xl-90 w-xxl-90 d-flex flex-end">

                         <AutoComplete className="input-form" placeholder="Search by Gene / Family / Class / Coupling / Pathway / Tissue"
                             name="gene" value={formValues.gene} suggestions={items} completeMethod={search} onChange={onChangeHandler} />

                        <input type="hidden" name="search_type" value={searchType} />
                        <div className="search-form">
                            {!loading && <button id="search" className="btn shadow-none" type="submit">Submit</button>}
                            {loading && <button id="search" className="btn shadow-none  d-flex align-items-center justify-content-center" type="submit" disabled>
                            <div className="spinner-grow text-light" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                            </button>}
                        </div>
                    </div>
                </form>
                <span>e.g. CHRM3, Glucagon receptor family, Adhesion class, GNAS, Creatine metabolism, Brain</span>
            </div>
            <div className="col-sm-1 col-md-3 col-lg-3 shrink hide-mobile"></div>
        </div>
    </div>
</section>
	  <Footer/>


// Cookie Banner
<CookieConsent
  buttonText="I Got It"
  style={{
    color: '#130d1f',
    background: '#d2d0d6'
  }}
  buttonStyle={{
    background: "#283779",
    color: "#FFFEFE"
  }}
  expires={150}>
  This website uses cookies to ensure you get the best experience on our website. &nbsp;
   <a href="https://www.cookiesandyou.com/" target="blank">Learn more</a>
</CookieConsent>

	</>
   )
}

export default HomePage