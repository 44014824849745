import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';

const BarPlot = ({jsonData, handleClickEnabled, hoverMessage, handleClickValue }) => {

  const handleClick = (data) => {
    // Extract the x-axis value of the clicked bar
    const xValue = data.points[0].x;

    // Pass the value to the parent component
    handleClickValue(xValue);
  };

  const layout = {
		showlegend: true,
		legend: {"orientation": "h", y: 1.3, x: 0},
		autosize: true,
		yaxis: {automargin:true},
        xaxis: {automargin:true},
        displaylogo: false,
         font: {
            size: 9,
            color: '#000000'
          }
	    };

  const  data = [ {

    type: 'bar',

    x: jsonData.geneUp,

    y: jsonData.valuesUp,

    base: 0,

    hovertemplate: hoverMessage ? '(%{x}, LFC: %{y}, %{customdata[0]}) - %{customdata[1]} | To view the detailed data, please click on the bar' : '(%{x}, LFC: %{y}, %{customdata[0]})',

    marker: {

      color: 'red'
    },

    name: 'Up-regulated',
    customdata: jsonData.extraInfoUp.map((info, index) => {
        const infoArray = info.split(',');
        return [infoArray[0], infoArray[1]]
        })
    },
  {

    type: 'bar',

    x: jsonData.geneDown,

    y: jsonData.valuesDown,

    base: 0,

    hovertemplate: hoverMessage ? '(%{x}, LFC: %{y}, %{customdata[0]}) - %{customdata[1]} | To view the detailed data, please click on the bar' : '(%{x}, LFC: %{y}, %{customdata[0]})',

    marker: {
      color: 'blue'
    },

    name: 'Down-regulated',
      customdata: jsonData.extraInfoDown.map((info, index) => {
        const infoArray = info.split(',');
        return [infoArray[0], infoArray[1]]
        })
  }]

  return (
    <>
      <Plot
        data={data}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        layout={layout}
        onClick={handleClickEnabled ? handleClick : null} // Conditionally attach the handleClick
      />
    </>
  );
};

export default BarPlot;
